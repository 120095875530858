.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f2ef;
  z-index: 999;
  transition: 1s all;
  opacity: 1;
  visibility: visible;

  .preloader-block {
    width: 240px;
    height: 240px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .preloader-logo {
    background-size: contain;
    width: 50px;
    height: 50px;
  }

  .preloader-line {
    margin-top: 40px;
    height: 3px;
    width: 240px;
    background-color: lightgrey;
  }

  .preloading {
    background-color: #000;
    width: 80px;
    height: 3px;
    animation: animation 1.8s infinite;
  }

  @keyframes animation {
    0% {
      transform: translateX(0rem);
    }
    50% {
      transform: translateX(10rem);
    }
    100% {
      transform: translateX(0rem);
    }
  }

  &.is-loaded {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
