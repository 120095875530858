.container-404 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-404 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-title {
        @include helvetica-bold;

        font-size: 40px;
        margin-bottom: 30px;

        @media screen and (max-width: 480px) {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    &-text {
        @include helvetica-light;

        font-size: 20px;

        @media screen and (max-width: 480px) {
            font-size: 12px;
        }
    }

    &-text-link {
        text-decoration: underline;
        text-decoration-skip-ink: none;
    }
}