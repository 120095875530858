.shopcart-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.shopcart-item {
  width: 100%;
  height: 320px;
  display: flex;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    height: 210px;
    padding: 0 20px 14px 10px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    background-color: #dbdbdb;
    width: calc(100% - 295px);

    @media screen and (max-width: 980px) {
      width: calc(100% - 275px);
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .shopcart-item-image {
    @include bg-standart;

    background-image: url("../assets/images/image-for-card.jpeg");
    margin: 10px 50px 10px 0;
    height: calc(100% - 20px);
    width: 245px;
    min-width: 245px;

    @media screen and (max-width: 980px) {
      margin-right: 30px;
    }

    @media screen and (max-width: 768px) {
      margin-right: 25px;
      width: 135px;
      min-width: 135px;
      height: 125px;
      min-height: 125px;
    }
  }

  .shopcart-item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;

    @media screen and (max-width: 980px) {
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      margin-right: 0;
      width: calc(100% - 170px);
      height: 135px;
    }

    &-title {
      @include helvetica-bold;

      font-size: 20px;
      margin-bottom: 15px;

      @media screen and (max-width: 768px) {
        margin-bottom: 2px;
        font-size: 12px;
      }
    }

    &-desc {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 140%;

      @media screen and (max-width: 768px) {
        @include helvetica-light;

        font-size: 10px;
        line-height: 130%;
      }
    }
  }

  .shopcart-item-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    @media screen and (max-width: 768px) {
      margin-right: auto;
    }

    &-item {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
      display: block;
      width: 40px;
      height: 40px;
      padding: 14px;

      @media screen and (max-width: 768px) {
        width: 25px;
        height: 25px;
        padding: 8px;
      }

      &--def {
        cursor: default;
      }

      &--minus {
        margin-right: 1px;

        .line-1 {
          background-color: #c4c4c4;
          height: 2px;
          width: 100%;
        }
      }

      &--plus {
        margin-left: 1px;

        .line-1 {
          background-color: #c4c4c4;
          height: 2px;
          width: 100%;
        }
        .line-2 {
          background-color: #c4c4c4;
          height: 2px;
          width: 100%;
          transform: rotate(90deg);
        }
      }

      &--quantity {
        @include helvetica-bold;

        font-size: 16px;
        padding: 0;
        border: 2px solid #dbdbdb;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
          @include helvetica-regular;

          font-size: 12px;
          border: none;
        }
      }
    }
  }

  .shopcart-item-price {
    @include helvetica-bold;

    font-size: 25px;
    text-align: right;
    display: flex;
    align-items: center;
    margin-left: 75px;
    flex-shrink: 0;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 980px) {
      margin-left: 10px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
      position: absolute;
      bottom: 25px;
      right: 20px;
    }
  }

  .shopcart-item-delete {
    font-size: 16px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #ff5555;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      right: auto;
      left: 20px;
      bottom: 25px;
      font-size: 10px;
    }
  }
}

.container-shopcart {
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.shopcart-bottom {
  background-color: #fdfdfd;
  padding: 50px 0;
  margin-top: 25px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding: 40px 0 30px;
    background-color: white;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-price {
    @include helvetica-bold;

    font-size: 50px;
    text-align: center;
    margin-bottom: 55px;

    &--sum {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      @include helvetica-regular;

      font-size: 20px;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 40px;

      span {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  &-btn {
    @include btn-normal;

    background-color: $dark-gray;
    margin-bottom: 45px;
    text-transform: uppercase;
    color: white;
    padding: 17px 34px;

    @media screen and (max-width: 768px) {
      padding: 10px 31px;
      font-size: 12px;
      margin-bottom: 25px;
    }
  }

  &-text {
    @include helvetica-light;

    line-height: 160%;
    font-size: 14px;
    color: $light-gray;
    text-align: center;
    max-width: 570px;
    line-height: 140%;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.shop-item {
  padding-top: 220px;

  @media screen and (max-width: 768px) {
    padding-top: 10px;
  }

  &-block {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &-info {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      width: calc(50% - 60px);
      margin-left: 60px;

      @media screen and (max-width: 768px) {
        margin: 0 auto;
        width: 100%;
        text-align: center;
      }

      &-title {
        @include helvetica-bold;

        line-height: 55px;
        letter-spacing: -0.02em;
        font-size: 40px;
        margin-bottom: 12px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &-price {
        @include helvetica-bold;

        letter-spacing: -0.02em;
        font-size: 30px;
        margin-bottom: 28px;

        @media screen and (max-width: 480px) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }

      &-text {
        font-size: 20px;
        letter-spacing: -0.02em;
        line-height: 160%;
        margin-bottom: 20px;

        @media screen and (max-width: 480px) {
          font-size: 12px;
          text-align: left;
        }
      }

      &-bottom {
        display: flex;
        margin-top: auto;

        @media screen and (max-width: 480px) {
          margin-right: -35px;
        }

        .shopcart-bottom-btn {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 768px) {
            margin: 0 auto;
          }

          @media screen and (max-width: 480px) {
            margin-left: -15px;
            width: 100%;
            font-size: 10px;
            padding: 15px;
            justify-content: space-between;
          }
        }

        .shopcart-bottom-btn-price {
          display: none;

          @media screen and (max-width: 768px) {
            display: inline;
            margin-left: 40px;
          }
        }
      }
    }
  }

  &-desc {
    font-size: 20px;
    margin-top: 130px;
    margin-bottom: 100px;

    span {
      @include helvetica-bold;
    }

    @media screen and (max-width: 768px) {
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }

  &-block--m {
    display: none;

    @media screen and (max-width: 768px) {
      display: flex;
    }

    &-title {
      @include helvetica-bold;
      font-size: 20px;
      margin: 100px auto 20px;
      text-align: center;
    }
  }
}

.shop-item-block-slider {
  width: calc(50% - 60px);
  height: 500px;
  margin-right: 60px;

  @media screen and (max-width: 768px) {
    margin-right: auto;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    height: 300px;
  }

  .swiper-slide {
    height: 100%;
    width: 100%;

    .shop-item-image {
      @include bg-standart;

      width: 100%;
      height: 100%;
      background-size: contain;
      background-image: url("../assets/images/image-for-card.jpeg");
    }
  }

  .swiper-button-next {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/icons/slider-arrow--next--dark.svg");
    height: 24px;
    width: 15px;
  }

  .swiper-button-prev {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/icons/slider-arrow--prev--dark.svg");
    height: 24px;
    width: 15px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;

  .modal-overlay {
    background-color: #1e1e1e59;
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 301;
  }

  .popup-cart-form {
    background-color: white;
    z-index: 302;
    position: relative;
    padding: 40px;
    max-width: 500px;
    transform: scale(0.9);

    @media screen and (max-width: 480px) {
      padding: 20px;
    }

    &-close {
      @include bg-standart;

      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      background-image: url("../assets/icons/close--dark.svg");
    }

    h3 {
      @include helvetica-bold;

      font-size: 30px;
      color: $dark-gray;
      margin-bottom: 20px;
    }

    #cart-form {
      display: flex;
      flex-direction: column;

      .cart-form-input {
        background-color: transparent;
        padding: 12px 12px 12px 1px;
        border: none;
        border-bottom: 2px solid $dark-line-1;
        margin-bottom: 20px;
      }

      .cart-form-input--submit {
        @include btn-normal;

        background-color: $dark-gray;
        text-transform: uppercase;
        color: white;
        padding: 17px 34px;
      }

      .form-message {
        font-size: 14px;
        text-align: center;

        &--good {
          color: rgb(74, 165, 0);
        }

        &--bad {
          color: rgb(180, 0, 0);
        }
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    transition: 0.3s all;

    .popup-cart-form {
      transition: 0.3s;
      transform: scale(1);
    }
  }
}

.order-info {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  padding-top: 170px;

  @media screen and (max-width: 768px) {
    padding-top: 120px;
  }

  span {
    @include helvetica-bold;
  }

  .shopcart-bottom-btn {
    margin-top: 40px;
    font-size: 16px;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
