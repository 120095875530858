.lg-sub-html {
  margin-bottom: 30px;
}

.lg-outer {
  .lg-object {
    max-width: 70%;
    max-height: 70%;
  }
}

.lg-backdrop {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(11px);
}

.lg-prev {
  @include bg-standart;
  background-image: url("../assets/icons/slider-arrow--prev--white.svg");
  height: 24px;
  width: 15px;
  background-color: transparent;
  padding: 0;

  &:after {
    content: "";
  }

  &:before {
    content: "";
  }
}

.lg-next {
  @include bg-standart;
  background-image: url("../assets/icons/slider-arrow--next--white.svg");
  height: 24px;
  width: 15px;
  background-color: transparent;
  padding: 0;

  &:after {
    content: "";
  }

  &:before {
    content: "";
  }
}

.lg-sub-html {
  p {
    line-height: 1;
    font-size: 20px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
}

.lg-close.lg-icon {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}

.lg-download.lg-icon {
  display: none;
}
