.logo {
  @include bg-standart;

  width: 42px;
  min-width: 42px;
  height: 47px;
  min-height: 47px;

  &--white {
    background-image: url("../assets/icons/logos/logo--white.svg");
  }

  &--dark {
    background-image: url("../assets/icons/logos/logo--dark.svg");
  }
}

.logo-full {
  @include bg-standart;

  width: 112px;
  min-width: 112px;
  height: 63px;
  min-height: 63px;

  &--white {
    background-image: url("../assets/icons/logos/logo-full--white.svg");
  }

  &--dark {
    background-image: url("../assets/icons/logos/logo-full--dark.svg");
  }
}

.search {
  @include bg-standart;
  @include btn-normal;

  width: 16px;
  min-width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("../assets/icons/header/search--dark.svg");
}

.search--mobile {
  @include bg-standart;
  @include btn-normal;

  width: 20px;
  min-width: 20px;
  height: 20px;
  background-image: url("../assets/icons/header/search--dark.svg");

  @media screen and (max-width: 480px) {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
}

.shopcart {
  @include bg-standart;

  position: relative;
  width: 18px;
  min-width: 18px;
  height: 20px;
  cursor: pointer;
  //background-image: url("../assets/icons/header/shopcart--dark.svg");

  &-badge {
    @include helvetica-bold;

    position: absolute;
    bottom: -9px;
    right: -5px;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $low-red;
    font-size: 10px;
  }
}

.btn {
  text-transform: uppercase;
  font-size: 20px;
  color: $dark-gray;
  border: 1px solid $dark-gray;
  padding: 15px 20px;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    padding: 5px 20px;
  }
}

.btn-secondary {
  opacity: 0.5;
}

a {
  transition-duration: 0.3s;
  color: $dark-gray;
  cursor: pointer;

  &:hover {
    transition-duration: 0.3s;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
}

a.btn {
  &:hover {
    color: $dark-gray;
    text-decoration: none;
  }
}

.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .swiper-button-prev {
    @include slider-arrow-prev;
  }

  .swiper-button-next {
    @include slider-arrow-next;
  }
}

.form-control {
  display: flex;
  flex-direction: column;

  #email-error {
    font-size: 14px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
}

.dark-theme {
  .btn {
    color: white;
    border: 1px solid white;
  }

  //.shopcart {
  //  background-image: url("../assets/icons/header/shopcart--white.svg");
  //}

  .search {
    background-image: url("../assets/icons/header/search--white.svg");
  }

  .search--mobile {
    background-image: url("../assets/icons/header/search--white.svg");
  }

  a {
    color: white;
    &:hover {
      // color: $light-gray;
    }
  }
  a.btn {
    &:hover {
      color: white;
    }
  }
}

.btn--disabled {
  opacity: 0.5;
}
