// plugins
@import "../../node_modules/lightgallery/css/lightgallery-bundle.min.css";

// base
@import "./base/mixins";
@import "./base/fonts";
@import "./base/normalize";
@import "swiper/css/bundle";
@import "./base/layout";

@import "./base/general";

// pages
@import "./pages/index";
@import "./pages/all-news";
@import "./pages/all-exhibitions";
@import "./pages/about";
@import "./pages/artists";
@import "./pages/page-404";
@import "./pages/shop";

// components
@import "./components/preloader";
@import "./components/gallery";
@import "./components/accordion";
@import "./components/header";
@import "./components/footer";
@import "./components/cards";
@import "./components/search-block";
@import "./components/mobile-menu";

// admin
@import "admin/admin";
