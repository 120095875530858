.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  border-bottom: 1px solid $dark-line-1;

  @media screen and (max-width: 980px) {
    height: 60px;
  }

  @media screen and (max-width: 980px) {
    z-index: 210;
  }

  .header--desktop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    z-index: 202;
    border-bottom: 1px solid transparent;

    @media screen and (max-width: 980px) {
      display: none;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .logo {
      margin-right: 80px;
    }

    nav {
      display: flex;
      justify-content: center;
      flex-grow: 1;

      ul {
        display: flex;
        align-items: center;

        li {
          padding: 0 15px;
          font-size: 22px;

          @media screen and (max-width: 1080px) {
            padding: 0 10px;
            font-size: 20px;
          }

          a {
            @include helvetica-light;
          }

          a.active {
            @include helvetica-bold;

            position: relative;
            padding-left: 15px;
            letter-spacing: -0.02em;

            &:after {
              position: absolute;
              left: 0;
              top: calc(50% - 1.5px);
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $dark-gray;
            }
          }
        }
      }
    }

    .lang {
      @include helvetica-bold;
      @include btn-normal;

      font-size: 20px;
      margin-left: 30px;
      text-transform: uppercase;
      margin-top: 5px;
    }

    .shopcart {
      margin-left: 30px;
    }
  }

  .header--mobile {
    border-bottom: 1px solid transparent;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: static;
    z-index: 202;

    @media screen and (max-width: 980px) {
      display: flex;
    }

    .container {
      z-index: 201;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .burger-menu {
      width: 17px;
      height: 16px;
      cursor: pointer;

      path {
        transition: 0.3s;
        fill: $dark-gray;
      }

      &.active path:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: -1px 3px;
        transition: transform 0.3s;
      }

      &.active path:nth-of-type(2) {
        opacity: 0;
        transition: 0.3s;
      }

      &.active path:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: 0px 13px;
        transition: transform 0.3s;
      }
    }

    .burger-menu--invisible {
      display: none;
    }

    .burger-menu--arrow {
      @include bg-standart;

      cursor: pointer;
      display: none;
      width: 18px;
      height: 16px;
      background-size: contain;
      background-image: url("../assets/icons/search-block-arrow--dark.svg");
    }

    .burger-menu--arrow--visible {
      display: block;
    }

    .logo {
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      margin: auto;

      &--white {
        background-image: url("../assets/icons/logos/logo-mobile--white.svg");
      }

      &--dark {
        background-image: url("../assets/icons/logos/logo-mobile--dark.svg");
      }
    }

    .search--mobile {
      display: none;
    }
  }
}

.dark-theme {
  .header {
    border-bottom: 1px solid transparent;
  }

  .lang {
    color: white;
  }

  .header--desktop {
    nav {
      ul {
        li {
          a.active {
            &:after {
              background-color: white;
            }
          }
        }
      }
    }
  }

  .header--mobile {
    .burger-menu {
      path {
        fill: white;
      }
    }

    .burger-menu--arrow {
      background-image: url("../assets/icons/search-block-arrow--white.svg");
    }
  }
}

.gray-theme {
  .header--border {
    border-bottom: 1px solid $dark-line-1;
  }
}
