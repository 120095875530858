.exhibitions-list {
  display: flex;
  flex-wrap: wrap;

  .exhibitions-list-item {
    width: 33%;
    display: flex;
    justify-content: center;
    padding: 0 20px;

    @media screen and (max-width: 1280px) {
      padding: 0 10px;
      width: 50%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
  }
}

.page-exhibitions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-exhibitions-years--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-exhibitions-years {
  display: flex;
  align-items: center;
  font-size: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  select {
    @include helvetica-bold;

    border: none;
    background-color: transparent;
    margin-left: 8px;
    text-decoration: underline;
  }

  &--gray {
    color: $light-gray;
    margin-top: 20px;
    margin-bottom: 50px;

    select {
      color: $light-gray;
    }
  }
}
