.card {
  width: 100%;
  height: auto;
  min-height: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 auto 30px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
  }

  .card-image--wrapper {
    overflow: hidden;
    position: relative;
    flex-shrink: 0;

    .card-image-badge {
      font-size: 10px;
      text-transform: uppercase;
      padding: 8px 10px 8px 20px;
      color: $dark-gray;
      background-color: white;
      position: absolute;
      top: 30px;
      left: 0;
      z-index: 1;
      line-height: 1;
    }
  }

  .card-image--wrapper-bg {
    background-color: #000;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    transition-duration: 0.3s;
  }

  .card-image {
    @include bg-standart;

    width: 100%;
    transition-duration: 0.3s;

    @media screen and (max-width: 480px) {
      min-height: 150px;
      height: 150px;
    }
  }

  &:hover {
    .card-image--wrapper-bg {
      opacity: 0.2;
      transition-duration: 0.3s;
    }

    .card-image {
      transform: scale(1.1);
      transition-duration: 0.3s;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;

    .card-content-date {
      font-size: 10px;
      color: $light-gray;
      margin-bottom: 3px;
      line-height: 20px;
    }

    .card-content-title {
      font-size: 16px;
      line-height: 18.4px;
      margin-bottom: 10px;
    }

    .card-content-btn {
      margin-top: auto;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 480px) {
        font-size: 10px;
      }

      .card-content-btn-text {
        font-size: 14px;
      }

      .card-content-btn-price {
        font-size: 14px;
      }
    }
  }
}

.swiper-slide-wrapper {
  .card-content {
    .card-content-btn {
      .card-content-btn-text {
        font-size: 12px;
      }

      .card-content-btn-price {
        font-size: 12px;
      }
    }
  }
}

.card-content-text {
  font-size: 14px;
  color: $light-gray;
  line-height: 19.6px;
  max-height: 60px;
  overflow: hidden;
}

.card--news {
  width: 320px;
  min-height: 480px;

  @media screen and (max-width: 480px) {
    min-height: 330px;
  }

  .card-image {
    min-height: 270px;
    height: 270px;

    @media screen and (max-width: 480px) {
      min-height: 150px;
      height: 150px;
    }
  }

  .card-content {
    padding: 15px 20px;

    .card-content-title {
      margin-bottom: 9px;
    }
  }
}

.card--shop {
  width: 320px;
  height: 590px;

  @media screen and (max-width: 480px) {
    height: 440px;
    width: 260px;
  }

  .card-image {
    min-height: 310px;
    height: 310px;

    @media screen and (max-width: 480px) {
      min-height: 150px;
      height: 150px;
    }
  }

  .card-content {
    padding: 30px 20px 20px;
    flex-grow: 1;

    .card-content-title {
      margin-bottom: 6px;
    }

    .card-content-text {
      @include helvetica-light;

      margin-bottom: 15px;
    }

    @media screen and (max-width: 480px) {
      .card-content-title {
        font-size: 14px;
      }

      .card-content-text {
        font-size: 12px;
      }

      .card-content-btn {
        padding: 14px 15px;
        .card-content-btn-text,
        .card-content-btn-price {
          font-size: 10px;
        }
      }
    }
  }
}

.card--exhibition {
  width: 320px;

  @media screen and (max-width: 480px) {
    min-height: 330px;
  }

  .card-image {
    min-height: 270px;
    height: 270px;

    @media screen and (max-width: 480px) {
      min-height: 150px;
      height: 150px;
    }
  }

  .card-content {
    padding: 25px 25px;

    @media screen and (max-width: 480px) {
      padding: 15px 20px;
    }

    .card-content-date {
      font-size: 16px;
      margin-bottom: 6px;

      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 3px;
      }
    }

    .card-content-title {
      margin-bottom: 6px;

      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 3px;
      }
    }

    .card-content-text {
      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
}

.card--about-content {
  margin-bottom: 0;

  .card-image {
    min-height: 240px;
    height: 240px;

    @media screen and (max-width: 768px) {
      min-height: 200px;
      height: 200px;
    }

    @media screen and (max-width: 480px) {
      min-height: 140px;
      height: 140px;
    }
  }

  .card-content {
    padding: 10px 0;

    .card-content-title {
      font-size: 12px;

      @media screen and (max-width: 768px) {
        font-size: 8px;
      }
    }
  }

  .card-content-title {
    line-height: 1.1 !important;
  }
}

.news-list {
  .card--news,
  .card--shop {
    width: 100%;
    min-height: 460px;

    @media screen and (max-width: 480px) {
      min-height: auto;
    }
  }
}

.exhibitions-list {
  .card--exhibition {
    width: 100%;

    @media screen and (max-width: 480px) {
      min-height: auto;
    }
  }

  .card--shop {
    width: 100%;
    height: 500px;

    .card-image {
      min-height: 270px;
      height: 270px;
    }

    @media screen and (max-width: 480px) {
      height: auto;
      min-height: auto;
    }
  }
}

.dark-theme {
  .card {
    background-color: $dark-gray;
    border: 2px solid $light-line-05;

    .card-image--wrapper {
      .card-image-badge {
        color: white;
        background-color: $dark-gray;
      }
    }

    .card-content {
      color: white;

      .card-content-date {
        color: $light-gray;
      }

      .card-content-text {
        color: $light-gray;
      }
    }
  }
}

a.card {
  &:hover {
    text-decoration: none;
  }
}
