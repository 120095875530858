.footer {
  padding-top: 50px;
  padding-bottom: 40px;
  background-color: white;

  .container {
    display: flex;
    justify-content: space-between;
  }

  .logo-full {
    @media screen and (max-width: 980px) {
      margin-bottom: 40px;
    }
  }

  .footer-info {
    display: flex;

    @media screen and (max-width: 980px) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .footer-form {
    display: flex;
    flex-direction: column;
    width: 370px;

    @media screen and (max-width: 980px) {
      width: 100%;
    }
  }

  .footer-form-disk {
    @include helvetica-light;

    color: $light-gray;
    max-width: 370px;

    @media screen and (max-width: 980px) {
      max-width: 100%;
    }

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }

  .footer-form-subscription {
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
      margin-bottom: 15px;
      justify-content: space-between;
    }

    &-mail--wrapper {
      position: relative;
      margin-right: 30px;
      flex-grow: 1;

      @media screen and (max-width: 480px) {
        margin-right: 10px;
      }
    }

    .footer-form-subscription-mail {
      line-height: 1.1;
      border: none;
      border-bottom: 2px solid $dark-line-1;
      padding: 12px 12px 12px 1px;
      background-color: transparent;
      width: auto;
      z-index: 1;

      @media screen and (max-width: 480px) {
        width: auto;
        font-size: 12px;
        padding: 8px 12px 8px 0;
      }

      &::placeholder {
        line-height: 1.1;
      }

      &:active,
      &:hover,
      &:focus {
        outline: 0;
        outline-offset: 0;
      }

      &:focus {
        border-bottom: 2px solid $dark-gray;
      }
    }

    .footer-form-subscription-mail.error {
      border-bottom: 2px solid $low-red;
    }

    .form-control {
      position: relative;

      @media screen and (max-width: 480px) {
        width: auto;
      }

      .form-input--clear {
        @include bg-standart;

        pointer-events: none;
        z-index: 2;
        background-color: transparent;
        border: none;
        position: absolute;
        top: 14px;
        right: 0;
        width: 10px;
        height: 10px;
        background-image: url("../assets/icons/clear--light-gray.svg");

        @media screen and (max-width: 480px) {
          top: 10px;
          width: 6px;
          height: 6px;
        }

        &.input-on-focus {
          pointer-events: auto;
          cursor: pointer;
          background-image: url("../assets/icons/clear--gray.svg");
        }
      }
    }

    .footer-form-subscription-submit {
      font-size: 10px;
      height: 40px;
      line-height: 0.9;

      @media screen and (max-width: 480px) {
        height: 30px;
      }
    }
  }

  .footer-list {
    &:first-child {
      margin-right: 50px;

      @media screen and (max-width: 1280px) {
        margin-right: 0;
      }
    }

    li {
      margin-bottom: 13px;
      color: $light-gray;

      @media screen and (max-width: 980px) {
        font-size: 12px;
      }

      a {
        color: $light-gray;
      }

      &:first-child {
        @include helvetica-bold;
        color: $dark-gray;

        @media screen and (max-width: 980px) {
          @include helvetica-regular;
          color: $light-gray;
        }
      }

      &:last-child {
        @media screen and (max-width: 1280px) {
          margin-bottom: 0;
        }
      }
    }

    &-link--wrapper {
      display: flex;
    }

    &-link--social {
      display: flex;
      margin-right: 15px;

      &-icon {
        @include bg-standart;

        display: block;
        width: 6px;
        height: 6px;
        margin-left: 6px;
        background-image: url("../assets/icons/link-arrow.svg");
      }
    }
  }

  .footer-form-subscription--accordion {
    @extend .footer-form-subscription;

    label.error {
      color: white;
    }
  }
}

.footer--mobile {
  padding-top: 40px;
  padding-bottom: 30px;

  @media screen and (max-width: 480px) {
    padding-top: 0;
    border-bottom: 2px solid $dark-gray;
  }

  .form-subs-message {
    color: white;
  }

  .container {
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-to-top {
    @include btn-normal;

    cursor: pointer;
    margin-top: 30px;
    color: $light-gray;
    font-size: 10px;
    text-decoration: underline;
  }

  .btn {
    padding: 5px 17.5px;
  }
}

.dark-theme {
  .footer {
    background-color: $dark-gray;

    .form-input--clear {
      background-image: url("../assets/icons/clear--gray.svg");
      &.input-on-focus {
        background-image: url("../assets/icons/clear--light-gray.svg") !important;
      }
    }

    .footer-form-disk {
      color: $light-gray;
    }

    .footer-form-subscription {
      display: flex;
      margin-bottom: 20px;

      @media screen and (max-width: 480px) {
        margin-bottom: 15px;
        justify-content: space-between;
      }

      &-mail--wrapper {
        position: relative;
        margin-right: 30px;

        @media screen and (max-width: 480px) {
          margin-right: 10px;
          width: 100%;
        }
      }

      .footer-form-subscription-mail {
        color: white;
        border-bottom: 2px solid $light-line-1;

        &:focus {
          border-bottom: 2px solid white;
        }
      }

      .footer-form-subscription-mail.error {
        border-bottom: 2px solid $low-red;
      }
    }

    .footer-list {
      li {
        color: $light-gray;

        a {
          color: $light-gray;
        }

        &:first-child {
          color: white;

          @media screen and (max-width: 980px) {
            color: $light-gray;
          }
        }
      }
    }

    .footer-form-subscription--accordion {
      label.error {
        color: white;
      }
    }
  }
}

.form-subs-message {
  font-size: 14px;
}
