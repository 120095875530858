@import './fonts';

// fonts
@mixin helvetica-bold {
    font-family: 'helvetica-bold', sans-serif;
    font-weight: 700;
}

@mixin helvetica-regular {
    font-family: 'helvetica-regular', sans-serif;
    font-weight: 400;
}

@mixin helvetica-light {
    font-family: 'helvetica-light', sans-serif;
    font-weight: 300;
}

// colors
$lightest-gray: #F0F0F0;
$light-gray: #A6A6A6;
$dark-gray: #1E1E1E;
$low-red: #FF5555;

$dark-line-1: rgba(0, 0, 0, 0.1);
$dark-line-05: rgba(0, 0, 0, 0.05);
$dark-line-02: rgba(0, 0, 0, 0.02);

$light-line-1: rgba(255, 255, 255, 0.1);
$light-line-05: rgba(255, 255, 255, 0.05);
$light-line-02: rgba(255, 255, 255, 0.02);

@mixin bg-standart {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin slider-arrow-prev {
    @include bg-standart;
    background-image: url('../assets/icons/slider-arrow--prev--white.svg');
    height: 24px;
    width: 15px;
}

@mixin slider-arrow-next {
    @include bg-standart;
    background-image: url('../assets/icons/slider-arrow--next--white.svg');
    height: 24px;
    width: 15px;
}

@mixin btn-normal {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}