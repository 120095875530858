.artists-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 70px;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  .artists-list-item--wrapper {
    border-bottom: 1px solid #dbdbdb;
    cursor: pointer;
    transition: 0.3s;

    &:first-child {
      border-top: 1px solid #dbdbdb;
    }

    &:hover {
      background-color: $lightest-gray;
      transition: 0.3s;

      .artists-list-item {
        text-decoration: underline;
      }
    }
  }

  .artists-list-item {
    @include helvetica-bold;

    font-size: 20px;
    padding: 25px 95px 25px 20px;
    position: relative;
    display: block;

    &:after {
      @include bg-standart;

      content: "";
      position: absolute;
      top: calc(50% - 5px);
      right: 20px;
      width: 5px;
      height: 10px;
      background-image: url("../assets/icons/link-arrow--artists.svg");
    }

    @media screen and (max-width: 480px) {
      padding: 25px 10px;
      font-size: 12px;
    }

    .artists-list-item-text {
      @include helvetica-regular;

      margin-top: 10px;
      font-size: 20px;
      height: 26px;
      overflow: hidden;
      word-break: break-all;

      @media screen and (max-width: 480px) {
        height: 44px;
      }
    }
  }

  .artists-list-item--search {
    &:after {
      top: calc(50% - 5px);
    }

    @media screen and (max-width: 768px) {
      .artists-list-item-title {
        text-decoration: underline;
        text-decoration-skip-ink: none;
      }

      .artists-list-item-text {
        font-size: 10px;
      }

      &:after {
        display: none;
      }
    }
  }
}
