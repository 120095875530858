body {
  @include helvetica-regular;

  background-color: white;
  color: $dark-gray;
  font-size: 16px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body.dark-theme {
  background-color: $dark-gray;
  color: white;
}

body.gray-theme {
  background-color: $lightest-gray;
}

main {
  // min-height: 900px;
  min-height: calc(100vh - 226px);

  @media screen and (max-width: 768px) {
    // min-height: 500px;
    min-height: 100vh;
  }

  @media screen and (max-width: 480px) {
    min-height: 100vh;
  }
}

.container {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    max-width: 1280px;
    padding: 0 20px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    padding: 0 15px;
    margin: 0 auto;
  }

  @media screen and (max-width: 480px) {
    max-width: 480px;
    min-width: 320px;
    margin: 0 auto;
  }
}

.section--md-none {
  display: block;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.section--md-block {
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
  }
}

.section--lg-none {
  display: block;

  @media screen and (max-width: 980px) {
    display: none;
  }
}

.section--lg-block {
  display: none;

  @media screen and (max-width: 980px) {
    display: block;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 180px 20px 70px;

  @media screen and (max-width: 980px) {
    padding: 110px 20px 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 110px 20px 40px;
    font-size: 30px;
  }

  @media screen and (max-width: 480px) {
    padding: 110px 10px 30px;
  }

  .page-header-title {
    @include helvetica-bold;

    font-size: 40px;
    letter-spacing: -0.02em;

    @media screen and (max-width: 480px) {
      font-size: 20px;
      margin-bottom: 6px;
    }

    .page-header-title-link {
      @include helvetica-regular;
    }

    &--m0 {
      margin: 0;
    }
  }

  &--news {
    display: flex;
    flex-direction: column;

    .page-header-title {
      max-width: 800px;
      // margin-bottom: 50px;
      text-align: center;
      letter-spacing: -0.02em;
      line-height: 140%;

      @media screen and (max-width: 480px) {
        // margin-bottom: 30px;
      }
    }
  }
}

.page-header-zero-pad {
  padding-left: 0;
}

.page-header--news-item {
  padding: 80px 20px 60px;

  @media screen and (max-width: 980px) {
    padding: 50px 20px 40px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px 30px;
    font-size: 30px;
  }

  @media screen and (max-width: 480px) {
    padding: 30px 10px 20px;
  }
}

.page-header--search {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 180px 20px 45px;

  @media screen and (max-width: 768px) {
    padding: 110px 10px 30px;
    align-items: center;
    justify-content: center;

    .page-header-title {
      text-align: center;
    }
  }

  .page-header-title-searchit {
    @include helvetica-regular;

    &--underline {
      text-decoration: underline;
      text-decoration-skip-ink: none;
      word-break: break-all;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      display: block;
    }
  }

  .page-header-subtitle {
    color: $light-gray;
    font-size: 20px;

    @media screen and (max-width: 480px) {
      @include helvetica-light;

      font-size: 12px;
    }
  }
}

.page-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px 50px;
  border-top: 1px solid $dark-line-1;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    padding: 30px 10px;
  }

  .page-footer-block {
    @include helvetica-bold;

    text-align: center;
    font-size: 14px;
    color: $light-gray;
    background-color: #f8f8f8;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    line-height: 1.1;

    @media screen and (max-width: 480px) {
      margin-bottom: 10px;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.page-footer-socials-wrapper {
  display: flex;
}

.news-gray-block {
  background-color: $lightest-gray;
  padding-bottom: 20px;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;

  .ya-share2__item {
    &:nth-child(1) {
      margin: 0 7.5px 0 15px !important;
    }

    &:nth-child(2) {
      margin: 0 7.5px !important;
    }

    &:nth-child(3) {
      margin: 0 0 0 7.5px !important;
    }
  }

  .ya-share2__badge {
    background-color: transparent !important;
  }

  .ya-share2__icon {
    width: 16px !important;
    height: 16px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .ya-share2__item_service_vkontakte .ya-share2__icon {
    background-image: url("../assets/icons/logos/socials/vk.svg") !important;
  }

  .ya-share2__item_service_facebook .ya-share2__icon {
    background-image: url("../assets/icons/logos/socials/fb.svg") !important;
  }

  .ya-share2__item_service_twitter .ya-share2__icon {
    background-image: url("../assets/icons/logos/socials/tw.svg") !important;
  }
}
