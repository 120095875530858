.about-content {
  margin-bottom: 70px;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  .about-content-text {
    font-size: 20px;
    max-width: 70%;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      max-width: 100%;
    }

    p {
      line-height: 1.6;
    }

    s {
      text-decoration: none;
      padding: 10px 20px;
      background-color: transparent;
      border: 1px solid $dark-gray;
      display: inline-block;
      line-height: 1;
      margin-bottom: 10px;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: $dark-gray;
        color: #fff;
        transition: 0.3s;
      }
    }

    &--catalog {
      margin-top: 20px;
    }
  }
}

.about-content--news-margin {
  margin-bottom: 30px;
}

.about-docs {
  display: flex;
  margin: 50px auto 0;
  max-width: 70%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }

  &-item {
    border-bottom: 1px solid $light-gray;
    margin-right: 20px;

    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &-link {
      display: flex;
      align-items: center;
      color: $light-gray;
      display: flex;
      font-size: 20px;
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      &:hover {
        text-decoration: none;
      }

      &-icon--download {
        @include bg-standart;

        display: block;
        width: 19.5px;
        min-width: 19.5px;
        height: 17.5px;
        margin-left: 10px;
        margin-bottom: 2px;
        background-image: url("../assets/icons/icon-download.svg");

        @media screen and (max-width: 768px) {
          width: 15.5px;
          min-width: 15.5px;
          height: 14.5px;
          margin-left: 4px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.about-content-text--light-gray {
  color: $light-gray;
}

.about-content {
  .about-content-banner {
    display: flex;
    flex-direction: column;
    width: 100%;

    .about-content-banner-image {
      @include bg-standart;

      width: 100%;
      height: 430px;
      margin-bottom: 10px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        height: 250px;
      }

      @media screen and (max-width: 480px) {
        height: 140px;
      }
    }

    .hidden-gallery {
      width: 0;
      height: 0;
      .about-content-banner-image {
        width: 0;
        height: 0;
      }
    }

    &-desc {
      font-size: 12px;
      margin-bottom: 30px;
      line-height: 1.1;

      @media screen and (max-width: 480px) {
        font-size: 8px;
      }
    }
  }

  .about-content-banner--about {
    width: 475px;
    height: 550px;
    margin: 0 25px 20px 0;
    float: left;

    @media screen and (max-width: 980px) {
      width: 100%;
      margin: 0 0 30px 0;
    }

    @media screen and (max-width: 768px) {
      height: 350px;
    }

    .about-content-banner-image {
      height: 100%;
      margin-bottom: 0;
    }
  }
}

.news-page {
  .about-content {
    .about-content-banner-image {
      height: 600px;

      @media screen and (max-width: 768px) {
        height: 350px;
      }
    }
  }
}

.about-content-works {
  margin-top: 70px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  &-item {
    width: calc(25% - 10px);
    margin-right: 10px;

    @media screen and (max-width: 980px) {
      width: calc(33% - 10px);
    }

    @media screen and (max-width: 768px) {
      width: calc(50% - 10px);
      margin-top: 30px;
    }

    .card {
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }
}

.pep-theme {
  padding: 40px;
  background-image: linear-gradient(#e8f0ff 0%, white 52.08%);
  color: #0e3481;
  min-height: 100vh;
}

.pep-theme {
  .gallery-item {
    width: 200px;
    padding: 5px;
  }

  .gallery-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.additional-block {
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
  max-width: 70%;

  @media screen and (max-width: 768px) {
    margin: 40px auto 0;
    max-width: 100%;
  }

  .additional-block-title {
    @include helvetica-bold;

    font-size: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .additional-block-list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      @include bg-standart;

      background-size: contain;
      width: 120px;
      height: 120px;
      margin-right: 20px;
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 480px) {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
}
