#header.admin-header {
  display: flex;
  background-color: white;
  padding: 26px 50px 31px 80px;
  border-bottom: 1px solid #dbdbdb;
}

#user-tools {
  display: flex;

  .admin-header-link {
    display: block;
    text-transform: uppercase;
    padding: 10px 10px 8px;
    background-color: transparent;
    border: 1px solid black !important;
    font-size: 16px;
    margin-right: 10px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: black !important;

    &:last-child {
      margin-right: 0;
    }
  }
}

h1 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 40px;
  margin-left: 10px;
  margin-bottom: 60px;
  color: #1e1e1e;
  letter-spacing: -0.02em;
}

#content:not(.colMS) {
  input[type="text"] {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    width: 100%;
  }

  .vDateField {
    max-width: 6.85em;
  }

  .datetimeshortcuts {
    a:not(#calendarlink0, #calendarlink1) {
      display: none;
    }
  }
}

#content.colMS {
  width: 440px;
  padding: 40px;
  background-color: #f8f8f8;
  margin-right: 0;
  min-height: calc(100vh - 105px);

  .module {
    background-color: #f8f8f8;
  }

  h1 {
    display: none;
  }
}

#nav-sidebar {
  flex: 0 0 440px;
  width: 440px;
  padding: 40px;
  background-color: #f8f8f8;
  min-height: calc(100vh - 105px);
}

.main.shifted > #nav-sidebar {
  left: 0;
}

#toggle-nav-sidebar {
  display: none;
}

.sticky {
  .module {
    background-color: #f8f8f8;
  }
}

.sidebar-item {
  background-color: white;
  color: #1e1e1e !important;
  padding: 20px 30px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  letter-spacing: -0.02em;

  &:hover {
    text-decoration: none;
  }

  &::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: white;
  }

  span.sidebar-item-desc {
    font-size: 12px;
    letter-spacing: -0.02em;
  }

  span.sidebar-item-update {
    color: #a6a6a6;
    font-size: 12px;
    letter-spacing: -0.02em;
  }
}

.sidebar-item[aria-current] {
  background-color: #1e1e1e;
  color: white !important;

  span.sidebar-item-desc {
    color: white;
  }
}

.main-info {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 105px);

  .main-info-h1 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #a6a6a6;
  }

  .main-info-h2 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #a6a6a6;
    margin-bottom: 45px;
  }

  .main-info-arrow--wrapper {
    display: flex;
    width: 100%;
    height: 120px;
  }

  .main-info-arrow {
    @include bg-standart;

    background-image: url("../assets/icons/arrow-for-admin-main-page.svg");
    margin-left: calc(50% - 265px);
    width: 270px;
    height: 117px;
  }
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .object-tools {
    height: 60px;
    flex-shrink: 0;

    .addlink {
      text-transform: lowercase;
      color: #1e1e1e;
      background-color: white;
      font-size: 12px;
      font-weight: normal;
      padding: 5px 10px;
      border-radius: 40px;
      border: 1px solid #1e1e1e;
      letter-spacing: -0.02em;
      background-image: none;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
#changelist-form {
  td,
  th {
    border-bottom: 1px solid #dbdbdb;
    padding: 25px 5px;
  }

  th.field-name {
    a {
      font-size: 20px;
      text-transform: lowercase;
      letter-spacing: -0.02em;
      color: #1e1e1e;
    }
  }
}

.submit-row {
  a.deletelink {
    box-sizing: content-box;
  }
}

.field-text_1_ru,
.field-text_2_ru,
.field-text_1_en,
.field-text_2_en {
  label {
    padding-left: 17px !important;
  }
}

#container {
  .form-row {
    padding: 10px 0;
  }

  .aligned {
    label {
      width: 100%;
      padding-bottom: 20px;
      font-weight: bold;
    }

    .help {
      margin-left: 0;
      padding-left: 0;
    }
  }

  img {
    display: inline;
  }

  input,
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  input[type="number"],
  input[type="tel"],
  textarea,
  select,
  .vTextField {
    padding: 5px 6px;
  }
}

.field-in_carousel {
  label {
    padding-bottom: 0 !important;
    width: auto !important;
  }
}

:root {
  --primary: #79aec8;
  --secondary: #417690;
  --accent: #f5dd5d;
  --primary-fg: #fff;

  --body-fg: #333;
  --body-bg: #fff;
  --body-quiet-color: #666;
  --body-loud-color: #000;

  --header-color: #ffc;
  --header-branding-color: var(--accent);
  --header-bg: var(--secondary);
  --header-link-color: var(--primary-fg);

  --breadcrumbs-fg: #c4dce8;
  --breadcrumbs-link-fg: var(--body-bg);
  --breadcrumbs-bg: var(--primary);

  --link-fg: #447e9b;
  --link-hover-color: #036;
  --link-selected-fg: #5b80b2;

  --hairline-color: #e8e8e8;
  --border-color: #ccc;

  --error-fg: #ba2121;
  --message-success-bg: #dfd;
  --message-warning-bg: #ffc;
  --message-error-bg: #ffefef;

  --darkened-bg: #f8f8f8; /* A bit darker than --body-bg */
  --selected-bg: #e4e4e4; /* E.g. selected table cells */
  --selected-row: #ffc;

  --button-fg: #fff;
  --button-bg: var(--primary);
  --button-hover-bg: #609ab6;
  --default-button-bg: var(--secondary);
  --default-button-hover-bg: #205067;
  --close-button-bg: #888; /* Previously #bbb, contrast 1.92 */
  --close-button-hover-bg: #747474;
  --delete-button-bg: #ba2121;
  --delete-button-hover-bg: #a41515;

  --object-tools-fg: var(--button-fg);
  --object-tools-bg: var(--close-button-bg);
  --object-tools-hover-bg: var(--close-button-hover-bg);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #79aec8;
    --primary-fg: #fff;

    --body-fg: #333;
    --body-bg: #fff;
    --body-quiet-color: #666;
    --body-loud-color: #000;

    --breadcrumbs-link-fg: #c4dce8;
    --breadcrumbs-bg: var(--primary);

    --link-fg: #447e9b;
    --link-hover-color: #036;
    --link-selected-fg: #5b80b2;

    --hairline-color: #e8e8e8;
    --border-color: #ccc;

    --error-fg: #ba2121;
    --message-success-bg: #dfd;
    --message-warning-bg: #ffc;
    --message-error-bg: #ffefef;

    --darkened-bg: #f8f8f8;
    --selected-bg: #e4e4e4;
    --selected-row: #ffc;

    --close-button-bg: #888;
    --close-button-hover-bg: #747474;
  }
}

select[name="main_image"] {
  pointer-events: none;
}

select#id_main_image,
#Exhibition_images-group select {
  pointer-events: none;
}

a.cancel-link {
  min-height: 34px;
}
