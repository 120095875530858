.news-list {
  display: flex;
  flex-wrap: wrap;

  .news-list-item {
    width: 33%;
    display: flex;
    justify-content: center;
    padding: 0 20px;

    @media screen and (max-width: 1280px) {
      padding: 0 10px;
      width: 50%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
  }
}

.news-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .news-pagination-links {
    display: flex;
    justify-content: center;
    align-items: center;

    &-item {
      @include helvetica-bold;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      height: 30px;
      font-size: 14px;
      border-right: 1px solid $dark-line-05;
      color: $light-gray;

      @media screen and (max-width: 480px) {
        height: 20px;
        font-size: 10px;
        padding: 0 20px;
      }

      &--no-border {
        border-right: none;
        padding: 0 0 0 25px;

        @media screen and (max-width: 480px) {
          padding: 0 0 0 20px;
        }

        &:last-child {
          padding: 0 25px;

          @media screen and (max-width: 480px) {
            padding: 0 20px;
          }
        }
      }
    }

    &-item.active {
      color: $dark-gray;
    }
  }
}

.dark-theme {
  .news-pagination {
    .news-pagination-links {
      &-item {
        border-right: 1px solid rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.5);
      }
      &-item.active {
        color: white;
      }
    }
  }
}
