.slider-main {
  width: 100%;
  height: 100vh;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-top: 167px;
    z-index: 10;

    &-text {
      font-size: 20px;
      margin-bottom: 35px;
      display: flex;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 15px;
      }

      &-item {
        margin: 0 20px;
      }

      &--mid {
        @include helvetica-light;
        font-size: 30px;
        margin-bottom: 35px;

        @media screen and (max-width: 480px) {
          font-size: 18px;
          margin-bottom: 50px;
        }
      }
    }

    &-title {
      @include helvetica-bold;
      font-size: 60px;
      margin-bottom: 35px;
      text-transform: uppercase;
      line-height: 1.3;
      max-width: 800px;

      @media screen and (max-width: 480px) {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
  }

  .swiper-slide-main {
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .btn {
      margin-bottom: 90px;
      z-index: 10;

      @media screen and (max-width: 480px) {
        padding: 9px 20px;
      }
    }

    .swiper-slide-main-bg {
      @include bg-standart;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: 1;
      background-image: url("../assets/images/for-shop-1.png");
    }
  }

  .swiper-pagination--bottom {
    top: auto;
    bottom: 0;
  }

  .swiper-pagination-progressbar {
    background-color: transparent;
  }

  .swiper-pagination-progressbar-fill {
    background-color: white;
  }
}

.section-main {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;

    @media screen and (max-width: 480px) {
      padding: 0 15px;
    }

    &-title {
      @include helvetica-bold;
      font-size: 40px;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    &-link {
      @include helvetica-regular;
      font-size: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      &-icon {
        @include slider-arrow-next;
        height: 10px;
        width: 7px;
        margin-left: 10px;

        @media screen and (max-width: 768px) {
          height: 6px;
          width: 4px;
          margin-left: 5px;
        }
      }
    }
  }

  .swiper-scrollbar {
    background-color: $light-line-02;
  }

  .swiper-scrollbar-drag {
    background-color: $light-line-05;
  }

  .swiper-button-prev {
    @include bg-standart;

    background: transparent;
    background-image: url("../assets/icons/bg-slides-arrow-l.svg");
    height: 100%;
    width: 44px;
    left: 0;
    top: 0;

    &-icon {
      @include slider-arrow-prev;
      z-index: 2;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }
  }

  .swiper-button-next {
    @include bg-standart;

    background: transparent;
    background-image: url("../assets/icons/bg-slides-arrow-r.svg");
    height: 100%;
    width: 44px;
    right: 0;
    top: 0;

    &-icon {
      @include slider-arrow-next;
      z-index: 2;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }
  }

  .swiper-button-disabled {
    display: none;
  }
}

.container-news {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-main-header {
    width: 100%;
  }

  .news-card {
    width: 280px;

    .news-card-content-title {
      font-size: 14px;
    }

    .news-card-content-text {
      font-size: 12px;
    }
  }

  .btn {
    @include helvetica-regular;

    font-size: 10px;
    width: 180px;
    padding: 10px 20px;
    margin-bottom: 20px;
    line-height: 0.9;
    display: block;
    text-align: center;
  }
}

.swiper-slide {
  width: 320px;
}

.slider-shop {
  .shop-card {
    @media screen and (max-width: 480px) {
      width: 260px;
    }
  }

  .swiper-slide {
    @media screen and (max-width: 480px) {
      width: 260px;
    }
  }
}
