@font-face {
  font-family: "helvetica-bold";
  src: local("Helvetica") url("../assets/fonts/Helvetica-Bold.woff")
    format("woff");
}

@font-face {
  font-family: "helvetica-regular";
  src: local("Helvetica") url("../assets/fonts/Helvetica-Regular.woff")
    format("woff");
}

@font-face {
  font-family: "helvetica-light";
  src: local("Helvetica") url("../assets/fonts/Helvetica-Light.woff")
    format("woff");
}
