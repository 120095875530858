.search-block {
  position: fixed;
  visibility: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(1.1);
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(11px);

  .search-block-close {
    @include bg-standart;

    cursor: pointer;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 73px;
    right: 80px;
    background-image: url("../assets/icons/close--dark.svg");

    @media screen and (max-width: 980px) {
      display: none;
    }
  }

  .search-block-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 780px;

    .search-block-form-input {
      font-size: 20px;
      padding: 12px 12px 12px 1px;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid $dark-line-1;
      margin-bottom: 150px;
      z-index: 1;

      @media screen and (max-width: 480px) {
        margin-bottom: 50px;
        padding: 10px 20px 10px 0;
        font-size: 14px;
      }

      &:active,
      &:hover,
      &:focus {
        outline: 0;
        outline-offset: 0;
      }

      &:focus {
        border-bottom: 2px solid $dark-gray;
      }
    }

    .form-control {
      position: relative;

      @media screen and (max-width: 980px) {
        padding: 0 20px;
      }

      .form-input--clear {
        @include bg-standart;

        pointer-events: none;
        z-index: 2;
        background-color: transparent;
        border: none;
        position: absolute;
        top: 20px;
        right: 0;
        width: 12px;
        height: 12px;
        background-image: url("../assets/icons/clear--light-gray.svg");

        @media screen and (max-width: 980px) {
          right: 20px;
        }

        @media screen and (max-width: 480px) {
          top: 17px;
          width: 10px;
          height: 10px;
        }

        &.input-on-focus {
          pointer-events: auto;
          cursor: pointer;
          background-image: url("../assets/icons/clear--gray.svg");
        }
      }
    }

    .btn {
      width: 200px;
      height: 50px;
      margin: 0 auto;

      @media screen and (max-width: 480px) {
        font-size: 10px;
        line-height: 0.9;
        width: 130px;
        height: 40px;
      }
    }
  }
}

.search-block--visible {
  visibility: visible;
  z-index: 205;
  transition: 0.3s;
  opacity: 1;
  transform: scale(1);
}

.dark-theme {
  .search-block {
    background-color: rgba(30, 30, 30, 0.7);

    .search-block-close {
      background-image: url("../assets/icons/close--white.svg");
    }

    .search-block-form {
      .search-block-form-input {
        color: white;
        border-bottom: 2px solid $light-line-1;

        &:focus {
          border-bottom: 2px solid $light-gray;
        }
      }

      .search-block-form-input.error {
        border-bottom: 2px solid $low-red;
      }

      .form-control {
        .form-input--clear {
          background-image: url("../assets/icons/clear--gray.svg");
          &.input-on-focus {
            background-image: url("../assets/icons/clear--light-gray.svg");
          }
        }
      }
    }
  }
}
