.mobile-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 0;
  visibility: hidden;
  transition: 0.3s;
  opacity: 0;
  transform: scale(1.1);

  .mobile-menu-item {
    @include helvetica-light;

    position: relative;
    color: $dark-gray;
    font-size: 14px;
    padding: 25px 20px;
    border-bottom: 1px solid $dark-line-1;

    &:before {
      @include bg-standart;

      content: "";
      position: absolute;
      top: calc(50% - 6px);
      right: 20px;
      width: 5px;
      height: 12px;
      background-image: url("../assets/icons/link-arrow-mobile-header--dark.svg");
    }

    &.active {
      @include helvetica-regular;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 3px);
        right: 20px;
        width: 6px;
        height: 6px;
        background-image: none;
        background-color: $dark-gray;
        border-radius: 50%;
      }
    }
  }

  .lang {
    @include helvetica-regular;
    @include btn-normal;

    font-size: 14px;
    color: $light-gray;
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }
}

.mobile-menu--visible {
  z-index: 100;
  visibility: visible;
  transition: 0.3s;
  opacity: 1;
  transform: scale(1);
}

.dark-theme {
  .mobile-menu {
    background-color: $dark-gray;
    .mobile-menu-item {
      color: white;
      border-bottom: 1px solid $light-line-1;
      &:before {
        background-image: url("../assets/icons/link-arrow-mobile-header--white.svg");
      }

      &.active {
        &:before {
          background-color: white;
        }
      }
    }
    .lang {
      color: $light-gray;
    }
  }
}
