.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  .a-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5px;

    .a-btn {
      font-size: 14px;
      margin: 0;
      position: relative;
      padding: 20px;
      width: 100%;
      color: $dark-gray;
      display: block;
      cursor: pointer;
      border-bottom: 2px solid $dark-line-02;
      // transition: 0.3s;

      span {
        display: block;
        position: absolute;
        height: 12px;
        width: 14px;
        right: 20px;
        top: 18px;

        &:after {
          content: "";
          width: 8px;
          height: 1px;
          background-color: $dark-gray;
          position: absolute;
          top: 10px;
          right: 0;
          transform: rotate(-35deg);
          transition: 0.3s;
        }

        &:before {
          content: "";
          width: 8px;
          height: 1px;
          background-color: $dark-gray;
          position: absolute;
          top: 10px;
          transform: rotate(35deg);
          transition: 0.3s;
        }
      }
    }

    .a-panel {
      width: 100%;
      color: $dark-gray;
      opacity: 0;
      height: auto;
      max-height: 0;
      overflow: hidden;
      padding: 0px 10px;
    }

    &.active {
      .a-btn {
        color: $dark-gray;
        border-bottom: none;
        transition: 0.3s;

        span {
          &::after {
            transform: rotate(35deg);
            transition: 0.3s;
          }
          &::before {
            transform: rotate(-35deg);
            transition: 0.3s;
          }
        }
      }

      .a-panel {
        padding: 15px 20px 30px;
        opacity: 1;
        max-height: 500px;
        border-bottom: 2px solid $dark-line-02;
      }
    }
  }
}

.dark-theme {
  .accordion {
    .a-container {
      .a-btn {
        color: white;
        border-bottom: 2px solid $light-line-02;
        span {
          &:after {
            background-color: white;
          }

          &:before {
            background-color: white;
          }
        }
      }
      .a-panel {
        color: $dark-gray;
      }
      &.active {
        .a-btn {
          color: white;
          border-bottom: none;
        }
        .a-panel {
          border-bottom: 2px solid rgba(255, 255, 255, 0.02);
        }
      }
    }
  }
}
